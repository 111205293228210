import React from 'react';
import { Toast } from 'react-bootstrap';

const AmplifyToast: React.FC<AmplifyToastProps> = ({
    toastType,
    autoHide = true,
    className = undefined,
    closeButton = true,
    customContent = '',
    delay = 1000,
    floating = true,
    icon = undefined,
    layoutContext = 'medium',
    offsetX = undefined,
    offsetY = undefined,
    onHide = () => undefined,
    placement = 'top-center',
    show = false,
    toastContent = '',
    toastTitle = 'Notification',
}) => {
    const customClassName = className ? className : '';

    const iconClassName = icon
        ? `icon-v2-${icon}`
        : `icon-v2-messaging-${toastType}`;

    const layoutContextClassName = `layout-context-${layoutContext}`;

    const offsetXStyle: OffsetStyle = offsetX
        ? { paddingLeft: offsetX, paddingRight: offsetX }
        : {};

    const offsetYStyle: OffsetStyle = offsetY
        ? { paddingTop: offsetY, paddingBottom: offsetY }
        : {};

    const placementClassName = floating
        ? `toast-${placement}`
        : `toast-stationary`;

    const toastContents =
        toastContent && typeof toastContent === 'string' ? (
            <p>{toastContent}</p>
        ) : (
            toastContent
        );

    const classNames = `${layoutContextClassName} ${placementClassName} ${customClassName}`;

    return (
        <Toast
            autohide={autoHide}
            bsPrefix="amplify-toast"
            className={classNames}
            style={Object.assign(offsetXStyle, offsetYStyle)}
            delay={delay}
            onClose={onHide}
            show={show}
        >
            <div className={`toast-inner toast-${toastType}`}>
                {customContent ? (
                    customContent
                ) : (
                    <>
                        <i
                            aria-hidden="true"
                            className={`${iconClassName} rhythm-icon toast-icon`}
                        />
                        <div className="toast-body">
                            <h2 className="toast-heading h5">{toastTitle}</h2>
                            <div className="toast-content">{toastContents}</div>
                        </div>
                    </>
                )}

                {closeButton && (
                    <button
                        type="button"
                        className="btn btn-icon-ghost rhythm-button toast-close"
                        data-dismiss="toast"
                        aria-label="Close Notification"
                        onClick={onHide}
                    >
                        <i className="icon-amplify-close" aria-hidden="true" />
                    </button>
                )}
            </div>
        </Toast>
    );
};

export default AmplifyToast;
