import React from "react";

const Loading = () => {
    return (
        <div className="amplify-spinner spinner-dark spinner-centered spinner-large" role="status">
            <div className="loader"></div>
            <span className="sr-only">Loading...</span>
        </div>
    );
}

export default Loading;
