import { useState } from 'react';

export const useToast: UseToastHook = () => {
    const [message, setMessage] = useState<ToastMessageType>();
    const [title, setTitle] = useState<ToastTitleType>();
    const [type, setType] = useState<ToastTypes>('error');

    const setToast: SetToast = (type, message, title) => {
        setType(type);
        setTitle(title);
        setMessage(message);
    };

    return {
        type,
        title,
        message,
        setError: (message, title = 'An error occured') => {
            setToast('error', message, title);
        },
        setInfo: (message, title = 'Notification') => {
            setToast('info', message, title);
        },
        setToast: setToast,
        reset: () => {
            setToast('error', undefined, undefined);
        },
    };
};
