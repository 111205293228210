import React, {createContext, useContext, useState, FC, PropsWithChildren} from 'react';
import {DistrictType} from "../components/Districts";
import {Enrollment} from "../services";

interface StoreProvider {
    districts: DistrictType[];
    enrollments: Record<number, { loading: boolean; enrollments: Enrollment[] }>;
    activeDistrict: null | DistrictType;
    setDistricts:  React.Dispatch<React.SetStateAction<DistrictType[]>>;
    setEnrollments:  React.Dispatch<React.SetStateAction<Record<number, { loading: boolean; enrollments: Enrollment[] }>>>;
    setActiveDistrict: React.Dispatch<React.SetStateAction<DistrictType | null>>;
}

const initialValues: StoreProvider = {
    districts: [],
    enrollments: {},
    activeDistrict: null,
    setDistricts: (value) => {},
    setEnrollments: (value) => {},
    setActiveDistrict: (value) => {},
};

const StoreContext = createContext(initialValues);

export const useStore = () =>  useContext(StoreContext);

export const StoreProvider : FC<PropsWithChildren<unknown>> = ({children}) => {
    const [districts, setDistricts] = useState(initialValues.districts);
    const [enrollments, setEnrollments] = useState(initialValues.enrollments);
    const [activeDistrict, setActiveDistrict] = useState<null | DistrictType>(null);

    return <StoreContext.Provider value={{ districts, enrollments, activeDistrict, setDistricts, setEnrollments, setActiveDistrict }}>
        {children}
    </StoreContext.Provider>
};
