import { getBbClassDetailsLink, getBbClassListLink, getEducatorLink } from '../shared/config';
import bffApi from './bff';
import impersonateApi from './impersonate';
import tutoringApi from "./tutoringApi";
import { axiosBffInstance, axiosImpersonateInstance, axiosTutoringApiInstance } from '../utils/api';

const bff = bffApi(axiosBffInstance);

const impersonate = impersonateApi(axiosImpersonateInstance);

const tutoringApiService = tutoringApi(axiosTutoringApiInstance);

const ApiClient = {
    bff: bff,
    tutoringApi: tutoringApiService,
    impersonate: {
        ...impersonate,
        signInTo: async (
            resource: 'educator' | 'groups' | 'class',
            ampUserSid: number,
            token?: string
        ) => {
            let redirectUrl;
            switch(resource) {
                case 'educator':
                    redirectUrl = getEducatorLink();
                    break;
                case 'groups':
                    redirectUrl = getBbClassListLink();
                    break;
                case 'class':
                    // TODO: requets to course and get the blackboardClassInternalId from the reponse
                    const blackboardClassInternalId = 'testId';
                    redirectUrl = getBbClassDetailsLink(blackboardClassInternalId);
                    break;
            }

            return {
                redirectUrl: redirectUrl,
                error: await impersonate.signIn(
                    ampUserSid,
                    redirectUrl,
                    token,
                ),
            };
        },
    },
};

export default ApiClient;
