import React from "react";

import './EmptyDistrictsList.scss';

const EmptyDistrictsList = () => {
    return (
        <div className="empty-districts-list-wrapper">
            <h2 className="h2 font-benton-sans mb-0">
                You do not have any assigned districts or groups at this time.
            </h2>
            <h3 className="h3 font-benton-sans mb-0 word-wrap">
                If you believe this is due to error, please contact tutoringoperations@amplify.com.
            </h3>
        </div>
    );
};

export default EmptyDistrictsList;
