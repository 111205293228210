import { useState, useEffect } from 'react';
import { AxiosError } from 'axios';

export const useApi = <T>(callback: () => Promise<T>, dependencies: Array<any> = []) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<T>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        setLoading(true);
        callback()
            .then((data) => {
                setError(undefined);
                setData(() => data);
            })
            .catch((err: AxiosError) => {
                setError(err.message);
                setData(() => undefined);
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);

    return { data, loading, error };
};
