import { createContext, useContext } from 'react';

export const defaultToastContextData: ToastContextInterface = {
    type: 'error',
    title: undefined,
    message: undefined,
    setToast: () => undefined,
    setError: () => undefined,
    setInfo: () => undefined,
    reset: () => undefined,
};

export const ToastContext = createContext<ToastContextInterface>(
    defaultToastContextData
);

export const useToastContext = () => useContext(ToastContext);
