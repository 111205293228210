import React from "react";
import { useToastContext } from "../../contexts/useToastContext";
import AmplifyToast from "../@amplify/AmplifyToast/AmplifyToast";
import { TOAST_DELAY } from "../../shared/constants";

const Toast = () => {
    const { type, title, message, reset } = useToastContext();

    if (!message) {
        return null;
    }

    return (
        <div className="toast-position-relative">
            <AmplifyToast
                autoHide={true}
                delay={TOAST_DELAY}
                show={!!message}
                toastContent={message}
                toastType={type}
                toastTitle={title}
                onHide={reset}
                layoutContext="compact"
                placement="top-right"
            />
        </div>
    );
}

export default Toast;
