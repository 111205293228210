import React, {useEffect} from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import Loading from "../components/Loading/Loading";
import Error from "../components/Error/Error";

// Automatically sign-in and silently reestablish your previous session, if you close the tab and reopen the application.
const AuthBarrierProvider: React.FC<AuthBarrierProviderProps> = ({ children }): JSX.Element => {
    const auth = useAuth();

    useEffect(() => {
        // track manual logout and inactive time session expiration for user logout
        auth.events.addUserSignedOut(() => {
            auth.removeUser();
            auth.signoutRedirect();
        });
    }, []);

    // automatically sign-in
    useEffect(() => {
        if (
            !hasAuthParams() &&
            !auth.isAuthenticated &&
            !auth.activeNavigator &&
            !auth.isLoading
        ) {
            auth.signinRedirect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

    if (auth.error) {
        return <Error title="An error occured" description='Third-party cookies should be allowed for this website.' />;
    }

    if (
        auth.activeNavigator === 'signoutRedirect' ||
        !auth.isAuthenticated
    ) {
        return <Loading />;
    }

    return <React.Fragment>{children}</React.Fragment>;
};

export default AuthBarrierProvider;
