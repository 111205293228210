import React, {useEffect, useState} from "react";
import api from "../../services/api";
import District from "./District";
import {useApi} from "../../hooks/useApi";
import {DistrictsProps, DistrictType} from ".";
import Loading from "../Loading/Loading";
import EmptyDistrictsList from "./EmptyDistrictsList";
import {GetAllDistrictsResponse} from "../../services";
import {generateDistrictKey} from "../../utils/helpers";
import {keycloakInstance} from "../../providers/AuthOidcProvider";
import {useToastContext} from "../../contexts/useToastContext";
import {datadogLogs} from "@datadog/browser-logs";
import {useStore} from "../../providers/StoreProvider";
import SubstituteSection from "./SubstituteSection";
import {SUBSTITUTE_ID_KEY, SUBSTITUTE_SESSION_KEY} from "../../shared/constants";

const Districts: React.FC<DistrictsProps> = ({
                                                 email,
                                                 accessToken,
                                                 setLoaderScreenVisible,
                                                 onLogout,
                                             }) => {
    const {setError} = useToastContext();
    const {
        districts,
        enrollments,
        setDistricts,
        setEnrollments,
        activeDistrict,
        setActiveDistrict
    } = useStore();

    const [activeDistrictKey, setActiveDistrictKey] = useState<string | undefined>();

    const {loading} = useApi<GetAllDistrictsResponse>(async () => {
        try {
            const mapped_accounts = await api.bff.getEmails(email);
            if (mapped_accounts.data.results.length === 0) return [];

            api.tutoringApi.syncSubstitutes(mapped_accounts.data.results);

            const school_mapped_accounts = mapped_accounts.data.results.filter((district: DistrictType) => district.inst_type === 'School');
            const uniq_account_user_ids: number[] = Array.from(new Set(school_mapped_accounts.map((district: DistrictType) => district.amp_user_sid)));

            const districts = mapped_accounts.data.results.reduce((acc: DistrictType[], district: DistrictType) => {
                if (uniq_account_user_ids.includes(district.amp_user_sid) &&
                    !acc.find((dist: DistrictType) => district.amp_user_sid === dist.amp_user_sid)) {
                    acc = [...acc, district]
                }

                return acc;
            }, []);

            api.tutoringApi.bbUserSessionsAsync(
                districts.map((district: DistrictType) => district.amp_user_sid),
                email
            );

            const institutions = await api.bff.getInstitutions(districts.map((district: DistrictType) => district.inst_sid));

            let userInfo: null | any = null;

            const results = districts
                .map((district: DistrictType, index: number) => {
                    const districtName = institutions
                        .data.results.find((inst: any) => inst.institution_uid === district.inst_sid)
                        .parent.institution_name;

                    return ({
                        ...district,
                        district_name: districtName,
                        enrollments: [],
                    })
                })
                .sort((a: DistrictType, b: DistrictType) => {
                    if (a.district_name.toLowerCase() < b.district_name.toLowerCase()) {
                        return -1;
                    }

                    if (a.district_name.toLowerCase() > b.district_name.toLowerCase()) {
                        return 1;
                    }

                    return 0;
                });

            if (keycloakInstance.authenticated) {
                try {
                    userInfo = await keycloakInstance.loadUserInfo();
                    const activeDistrict = results.filter((district: DistrictType) => district.email === userInfo.email)[0];

                    if (sessionStorage.getItem(SUBSTITUTE_ID_KEY)) {
                        setActiveDistrict({
                            ...activeDistrict,
                            district_name: 'substitute group',
                            email: SUBSTITUTE_SESSION_KEY
                        });
                    } else {
                        setActiveDistrict(activeDistrict);
                    }
                } catch (error) {
                    datadogLogs.logger.error(`${email} faced an issue during Amplify session user info loading`, {
                        user_email: email
                    }, error as Error);
                    setActiveDistrict(null);
                }
            } else {
                setActiveDistrict(null);
            }

            setDistricts(results);

            return results;
        } catch (error) {
            datadogLogs.logger.error(`${email} faced an issue during data loading`, {
                user_email: email
            }, error as Error);
            setError('Something happened!');
        }

    }, []);

    useEffect(() => {
        if (Object.keys(districts).length) return;

        setEnrollments(value => {
            districts.forEach((item: any) => {
                value[item.amp_user_sid] = {
                    loading: true,
                    enrollments: []
                }
            });

            return {...value};
        });
    }, [districts]);

    useEffect(() => {
        setActiveDistrictKey(activeDistrict?.email);
    }, [activeDistrict]);

    return (
        <div className="tutoring-portal-districts container">
            <SubstituteSection
                enrollments={enrollments}
                email={email}
                accessToken={accessToken}
                setLoaderScreenVisible={setLoaderScreenVisible}
                activeAmpUserSid={activeDistrict?.amp_user_sid}
                isActive={activeDistrict?.email === SUBSTITUTE_SESSION_KEY}
                activeDistrictKey={activeDistrictKey}
                onLogout={onLogout}
                onRedirectLinkClick={() => {
                    setActiveDistrict(() => ({
                        district_name: 'substitute group',
                        email: SUBSTITUTE_SESSION_KEY,
                    } as DistrictType));
                }}
            />
            {loading ? <Loading/> : districts?.length ? districts?.map(district => {
                const districtKey = generateDistrictKey(district);

                return (
                    <District
                        key={districtKey}
                        email={email}
                        accessToken={accessToken}
                        district={district}
                        districts={districts}
                        districtKey={districtKey as string}
                        activeDistrictKey={activeDistrictKey}
                        onRedirectLinkClick={() => setActiveDistrict(district)}
                        onLogout={onLogout}
                        setLoaderScreenVisible={setLoaderScreenVisible}
                        mappedAccountIds={districts.map(({amp_user_sid}) => amp_user_sid)}
                    />
                );
            }) : <EmptyDistrictsList/>}
        </div>
    );
}

export default Districts;
