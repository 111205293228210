import React, {useEffect, useState} from 'react';
import {datadogLogs} from '@datadog/browser-logs';
import Header from '../../components/Header/Header';
import Greetings from '../../components/Greetings/Greetings';
import Districts from '../../components/Districts/Districts';
import {useAuth} from 'react-oidc-context';
import LoaderScreen from '../../components/LoaderScreen/LoaderScreen';
import {District as DistrictType} from '../../services';
import {getOidcRedirectUri} from "../../shared/config";
import {keycloakInstance} from "../../providers/AuthOidcProvider";
import api from "../../services/api";
import {useStore} from "../../providers/StoreProvider";
import {useVisibility} from "../../hooks/useVisibility";
import {IS_SESSION_INITIALIZING, SUBSTITUTE_ID_KEY} from "../../shared/constants";

const Home = () => {
    const auth = useAuth();
    const { isTabActive } = useVisibility();
    const [isLoaderScreenVisible, setLoaderScreenVisible] = useState(false);
    const { districts, activeDistrict, setActiveDistrict } = useStore();

    const onLogout = () => {
        datadogLogs.logger.info(`Session has been closed for ${activeDistrict?.email}`);
        sessionStorage.removeItem(SUBSTITUTE_ID_KEY);
        window.open(`${getOidcRedirectUri()}/logout-start`);
    }

    const refreshTokenIfTabIsActive = (isActive: boolean) => {
        if (!isActive) {
            auth.stopSilentRenew();
        }

        if (isActive) {
            // Check if Master realm session exist end extend access_token when yes, end redirect to login page when not
            auth.signinSilent()
                .then(() => {
                    auth.startSilentRenew();
                })
                .catch(() => {
                    sessionStorage.removeItem(SUBSTITUTE_ID_KEY);
                    sessionStorage.removeItem(IS_SESSION_INITIALIZING);
                    auth.removeUser();
                    auth.signoutRedirect();
                });

            keycloakInstance.updateToken(5)
                .catch(() => {
                    if (activeDistrict && !sessionStorage.getItem(IS_SESSION_INITIALIZING)) {
                        api.tutoringApi.deleteBBUserSession(auth.user?.profile.email as string, activeDistrict.amp_user_sid);
                        setActiveDistrict(null);
                    }
                });

            api.tutoringApi.syncSubstitutes(districts);
        }
    };

    useEffect(() => {
        refreshTokenIfTabIsActive(isTabActive);
    }, [isTabActive]);

    useEffect(() => {
        if (keycloakInstance) {
            keycloakInstance.onAuthLogout = async () => {
                if (activeDistrict) {
                    api.tutoringApi.deleteBBUserSession(auth.user?.profile.email as string, activeDistrict.amp_user_sid);
                    sessionStorage.removeItem(SUBSTITUTE_ID_KEY);
                }

                setActiveDistrict(null);
            };
        }
    }, [activeDistrict]);

    return (
        <div className='tutoring-portal-wrapper'>
            <Header activeDistrict={activeDistrict} onLogout={onLogout}/>
            {isLoaderScreenVisible && <LoaderScreen/>}
            <Greetings name={auth.user?.profile.given_name}/>
            {auth.user?.profile.email ? (
                <Districts
                    email={auth.user?.profile.email}
                    accessToken={auth.user?.access_token}
                    setLoaderScreenVisible={setLoaderScreenVisible}
                    onLogout={onLogout}
                />
            ) : null}
        </div>
    );
}

export default Home;
