import React from 'react';
import {ConfirmationModalHeaderProps} from "./index";

const ConfirmationModalHeader: React.FC<ConfirmationModalHeaderProps> = ({ text }) => (
    <h3 className="modal-title confirmation-modal-header">
        {text}
    </h3>
);

export default ConfirmationModalHeader;
