import { AxiosInstance } from 'axios';
import { ImpersonateApi } from '.';

const impersonateApi: ImpersonateApi = (axios: AxiosInstance) => ({
    signIn: async (ampUserSid, redirectUri, token, userType = 'teacher') => {
        if (!token) {
            return 'Not authorized.';
        }

        try {
            const response = await axios.post('', {
                kc_access_token: token,
                target_amp_user_sid: ampUserSid,
                target_user_type: userType,
                redirect_uri: redirectUri,
            });
            if (response.status !== 302) {
                return 'Can not open the link.';
            }
        } catch (e: any) {
            return e.toString();
        }
    },
});

export default impersonateApi;
