import React from 'react';

const LoaderScreen = () => {
    return (
        <div
            style={{
                height: 'calc(100vh - 60px)',
                width: '100%',
                position: 'fixed',
                top: 60,
                zIndex: 10,
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <div
                style={{
                    width: 180,
                    height: 180,
                    marginTop: 240,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <div
                    className="amplify-spinner spinner-large spinner-dark" role="status">
                    <div className="loader"></div>
                </div>
            </div>
        </div>
    )
};

export default LoaderScreen;
