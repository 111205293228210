import React from 'react';
import Home from './pages/Home/Home';
import { ToastContext } from './contexts/useToastContext';
import { useToast } from './hooks/useToast';
import Toast from './components/Toast/Toast';
import {StoreProvider} from "./providers/StoreProvider";

const App = () => (
    <ToastContext.Provider value={useToast()}>
        <StoreProvider>
            <Home />
            <Toast />
        </StoreProvider>
    </ToastContext.Provider>
)

export default App;
