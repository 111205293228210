import axios from "axios";
import { getImpersonateUrl, getTutoringBffUrl, getTutoringApiUrl } from "../shared/config";

export const axiosBffInstance = axios.create({
    baseURL: getTutoringBffUrl(),
});

export const axiosImpersonateInstance = axios.create({
    baseURL: getImpersonateUrl(),
});

export const axiosTutoringApiInstance = axios.create({
    baseURL: getTutoringApiUrl(),
});
