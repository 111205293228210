import {User} from "oidc-client-ts"
import {getOidcAuthority, getOidcClientId} from "../shared/config";
import {District} from "../services";

export const getUser = () => {
    const oidcStorage = sessionStorage.getItem(
        `oidc.user:${getOidcAuthority()}:${getOidcClientId()}`
    );

    if (!oidcStorage) {
        return undefined;
    }

    return User.fromStorageString(oidcStorage);
};

export const removeQueryParamsFromURL = () => {
    window.history.replaceState(
        {},
        document.title,
        window.location.pathname
    );
};

export const generateDistrictKey = (district?: District | null) => {
    if (district) {
        return `${district?.email}`;
    }

    return;
};

export const wrapFn = (fn: Function, ...params: any[]) => (() => {
    fn(...params);
});

export const sliceIntoChunks = <T>(arr: T[], chunkSize: number): Array<T[]> => {
    const res: Array<T[]> = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk: T[] = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}
