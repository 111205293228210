import React from 'react';
import Logo from "./components/Logo/Logo";

const LogoutEnd = () => (
    <div
        style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}
    >
        <div>
            <Logo />
        </div>
        <h2>You have successfully logged out of your district.</h2>
        <div
            className='tutoring-portal-link btn-secondary btn btn-oval btn-small'
            onClick={() => window.close()}
        >
            Close tab
        </div>
    </div>
);

export default LogoutEnd;
