import React from 'react';
import AmplifyModal from '@amplify/amplify-modal';
import ConfirmationModalHeader from './ConfirmationModalHeader';

import './ConfirmationModal.scss';
import {ConfirmationModalProps} from "./index";

const Actions = ({ onClose, onAction }: any) => (
    <>
        <button
            className="btn btn-oval btn-secondary"
            onClick={onClose}
        >
            Cancel
        </button>
        <button
            className="btn btn-oval"
            onClick={onAction}
        >
            Go to Blackboard
        </button>
    </>
)

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    isOpen,
    isStartButtonDisabled,
    isLoading,
    actionButtonTitle,
    title,
    onClose,
    onAction,
    children,
}) => (
    <AmplifyModal
        header={<ConfirmationModalHeader text={title} />}
        open={isOpen}
        onClose={onClose}
        actions={
            <Actions onClose={onClose} onAction={onAction}/>
        }
    >
        {children}
    </AmplifyModal>
);

export default ConfirmationModal;
