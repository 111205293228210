import axios, {AxiosInstance} from 'axios';
import { datadogLogs } from '@datadog/browser-logs';
import {GetAllEnrolledCourses, MappedAccount, SyncSubstituteRequest} from "./index";
import {SyncSubstituteDTO} from "../dto/SyncSubstituteDTO";

const tutoringApi = (axios: AxiosInstance) => ({
    enrollments: async (ampUserSid: number) => {
        try {
            const result = await axios.get<GetAllEnrolledCourses>('course-user', {
                params: {
                    user_id: `urn:mclass-staff:${ampUserSid}`
                }
            });

            const pages_count = result.data.pagination_info.pages_count;

            if (pages_count > 1) {

                let requests = [];

                for (let i = 2; i <= pages_count; i++) {
                    requests.push(
                        axios.get<GetAllEnrolledCourses>('course-user', {
                            params: {
                                user_id: `urn:mclass-staff:${ampUserSid}`,
                                page: i
                            }
                        })
                    );
                }

                const pagination = await Promise.all(requests);

                pagination.forEach((res: any) => {
                    result.data.results = [...result.data.results, ...res.data.results];
                });

            }

            return result;
        } catch (error) {
            datadogLogs.logger.error(`District enrollments loading failed for user id: ${ampUserSid}`, {
                user_id: `urn:mclass-staff:${ampUserSid}`
            }, error as Error);
            throw error;
        }
    },
    courseDetails: async (id: number) => {
        return await axios.get(`course/${id}`);
    },
    bbUserSessions: async (ids: number[], masterEmail: string)=> {
        return await axios.post<{ results: { user: { externalId: string } }[] }>('user/blackboard-sessions', {
            users: ids.map(id => ({ id: `urn:mclass-staff:${id}`, email: masterEmail }))
        })
    },
    bbUserSessionsAsync: async (ids: number[] | string[], masterEmail: string)=> {
        return await axios.post<{ results: { user: { externalId: string } }[] }>('user/blackboard-sessions?async=true', {
            users: ids.map(id => ({ id: `urn:mclass-staff:${id}`, email: masterEmail }))
        })
    },
    getBBUserSessions: async (email: string) => {
        const base64Email = btoa(email);
        return await axios.get<{ results: { user: { id: string } }[] }>(`user/blackboard-sessions/${base64Email}`);
    },
    writeBBUserSession: async (masterEmail: string, id: number) => {
        return await axios.post('user/blackboard-sessions/report', {
            operation: 'add',
            user: {
                email: masterEmail,
                id: `urn:mclass-staff:${id}`
            }
        });
    },
    deleteBBUserSession: async (masterEmail: string, id: number) => {
        return await axios.post('user/blackboard-sessions/report', {
            operation: 'delete',
            user: {
                email: masterEmail,
                id: `urn:mclass-staff:${id}`
            }
        });
    },
    syncSubstitutes: async (users: MappedAccount[]) => {
        return await axios.post('user/schedule-subs', {
            users: SyncSubstituteDTO.transformToRequest(users)
        })
    }
});

export default tutoringApi;
