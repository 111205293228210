import React, {useEffect, useState} from "react";
import {datadogLogs} from '@datadog/browser-logs';
import AmplifyToggle from '@amplify/amplify-toggle';
import classnames from 'classnames';
import RedirectLink from '../RedirectLink/RedirectLink';
import {SubstituteSectionProps} from '.';
import {
    getBbClassDetailsLink,
    getImpersonateUrl, getOidcRedirectUri,
} from '../../shared/config';
import api from '../../services/api';

import './District.scss';

import {keycloakConfig, keycloakInstance} from "../../providers/AuthOidcProvider";
import Keycloak from "keycloak-js";
import {Enrollment} from "../../services";
import {
    IS_SESSION_INITIALIZING,
    SUBSTITUTE_ID_KEY,
    SUBSTITUTE_SESSION_KEY,
    SUBSTTUTION_TOGGLE_STATE
} from "../../shared/constants";
import Link from "../Link/Link";

type EnrollmentWithAmpUserSid = Enrollment & { amp_user_sid: number };

const SubstituteSection: React.FC<SubstituteSectionProps> = ({
                                                                 accessToken,
                                                                 enrollments,
                                                                 email,
                                                                 setLoaderScreenVisible,
                                                                 activeAmpUserSid,
                                                                 isActive,
                                                                 activeDistrictKey,
                                                                 onLogout,
                                                                 onRedirectLinkClick
                                                             }) => {
    const targetType = 'teacher';
    const [susbstitutes, setSubstitutes] = useState<EnrollmentWithAmpUserSid[]>([]);
    const [isVisible, setIfVisible] = useState(true);
    const [activeSubSessionId, setActiveSubSessionId] = useState<number | undefined>();

    useEffect(() => {
        const addedCourseIds: number[] = [];

        const records = Object.keys(enrollments).reduce((acc: EnrollmentWithAmpUserSid[], key: any) => {

            return [...acc, ...enrollments[key].enrollments
                .map(enroll => ({...enroll, amp_user_sid: key}))
                .reduce((acc: EnrollmentWithAmpUserSid[], enrollment) => {

                    if (addedCourseIds.includes(enrollment.course.id)) return acc;
                    addedCourseIds.push(enrollment.course.id);

                    return [...acc, enrollment];
                }, [])
            ];
        }, []);

        setSubstitutes(records
            .filter(enroll => enroll.role === 'SUBSTITUTE' && enroll.available)
            .sort((a: EnrollmentWithAmpUserSid, b: EnrollmentWithAmpUserSid) => {
                if (a.course.title.toLowerCase() < b.course.title.toLowerCase()) {
                    return -1;
                }

                if (a.course.title.toLowerCase() > b.course.title.toLowerCase()) {
                    return 1;
                }

                return 0;
            })
        );

        if (sessionStorage.getItem(SUBSTITUTE_ID_KEY)) {
            setActiveSubSessionId(Number(sessionStorage.getItem(SUBSTITUTE_ID_KEY)));
        }

        if (sessionStorage.getItem(SUBSTTUTION_TOGGLE_STATE)) {
            setIfVisible(JSON.parse(sessionStorage.getItem(SUBSTTUTION_TOGGLE_STATE) as string));
        }
    }, [enrollments]);

    useEffect(() => {
        if (activeAmpUserSid) {
            setActiveSubSessionId(activeAmpUserSid);
        } else {
            setActiveSubSessionId(undefined);
        }

    }, [activeAmpUserSid]);

    const initializeKeycloakSession = () => {
        if (!keycloakInstance.authenticated) {
            const instance = new Keycloak(keycloakConfig);
            sessionStorage.setItem(IS_SESSION_INITIALIZING, 'true');

            instance.init({
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: `${getOidcRedirectUri()}/silent-check-sso.html`,
            }).then(initialized => {
                if (initialized) {
                    sessionStorage.removeItem(IS_SESSION_INITIALIZING);
                    window.location.reload();
                } else {
                    setTimeout(() => {
                        initializeKeycloakSession();
                    }, 1000);
                }
            }).catch(() => {
                setTimeout(() => {
                    initializeKeycloakSession();
                }, 1000);
            });
        }
    };

    const onGroupClick = async (event: any, id: number, amp_user_sid: number, externalId?: string,) => {
        event.preventDefault();

        setLoaderScreenVisible?.(true);
        let courseDetails;

        if (!externalId) {
            courseDetails = await api.tutoringApi.courseDetails(id);
        }

        if (activeSubSessionId) {
            window.open(getBbClassDetailsLink(externalId ?? courseDetails?.data.external_id));
        } else {
            sessionStorage.setItem(SUBSTITUTE_ID_KEY, id.toString());
            event.target.children.redirect_uri.value = getBbClassDetailsLink(externalId ?? courseDetails?.data.external_id);
            api.tutoringApi.writeBBUserSession(email, amp_user_sid);
            datadogLogs.logger.info(`Blackboard Classes session has started for the substitute group ID ${id}`);
            event.target.submit();
            initializeKeycloakSession();
            onRedirectLinkClick();
        }

        setLoaderScreenVisible?.(false);
    };

    const onTogglerChanged = (event: any) => {
        if (event.target.checked) {
            sessionStorage.setItem(SUBSTTUTION_TOGGLE_STATE, 'true');
            setIfVisible(true);
        } else {
            sessionStorage.setItem(SUBSTTUTION_TOGGLE_STATE, 'false');
            setIfVisible(false);
        }
    };

    return Object.keys(enrollments).every((key: any) => enrollments[key].loading === false) && susbstitutes.length ? (
        <div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 320}}>
                <AmplifyToggle
                    enabledText="On"
                    disabledText="Off"
                    labelText=""
                    onChange={onTogglerChanged}
                    value={isVisible}
                />
                <div>Show my substitute groups</div>
            </div>
            {isVisible && (
                <div className={classnames("tutoring-portal-district", {active: isActive})}
                >
                    <div className="tutoring-portal-district-header tutoring-portal-border-bottom substitute">
                        <h3>Substitute groups</h3>
                        <div className="tutoring-portal-active-district-wrapper">
                            {isActive ? (
                                <React.Fragment>
                                    <span className="active-label">
                                        <span className="dot"></span>
                                        ACTIVE DISTRICT
                                    </span>
                                    <Link
                                        type="default"
                                        onClick={onLogout}
                                        classNames='cursor-pointer logout-link'
                                    >
                                        Log out of substitute group
                                    </Link>
                                </React.Fragment>
                            ) : null}
                        </div>
                    </div>
                    <div className='totoring-portal-join-session'>Groups</div>
                    <ul className='tutoring-portal-sessions'>
                        {susbstitutes.map((enrollment) => (
                            <li key={`${enrollment.course?.id}_${enrollment.course?.title}`}>
                        <span className={classnames("tutoring-portal-session-card substitute")}>
                            <RedirectLink
                                action={getImpersonateUrl()}
                                kcAccessToken={accessToken as string}
                                targetAmpUserSid={enrollment.amp_user_sid}
                                targetUserType={targetType}
                                redirectUri=''
                                type='simple'
                                onClick={(event) => onGroupClick(event, enrollment.course?.id, enrollment.amp_user_sid, enrollment.course?.external_id)}
                                disabled={(!!activeSubSessionId && enrollment.course.id !== activeSubSessionId) || activeDistrictKey !== undefined && activeDistrictKey !== SUBSTITUTE_SESSION_KEY}
                            >
                                {enrollment.course?.title}
                            </RedirectLink>
                            <span title={enrollment.course?.school_name}>{enrollment.course?.school_name}</span>
                        </span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    ) : null;
}

export default SubstituteSection;
