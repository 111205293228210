import React from 'react';
import ReactDOM from 'react-dom/client';
import {datadogLogs} from '@datadog/browser-logs';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthOidcProvider from './providers/AuthOidcProvider';
import AuthBarrierProvider from './providers/AuthBarrierProvider';
import LogoutStart from "./LogoutStart";

import './index.scss';
import LogoutEnd from "./LogoutEnd";
import {Envs, getEnv, DATADOG_PUBLIC_API_KEY} from "./shared/config";

if (getEnv() !== Envs.Local) {
    datadogLogs.init({
        clientToken: DATADOG_PUBLIC_API_KEY,
        site: 'datadoghq.com',
        service: 'tutor-portal',
        env: getEnv(),
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
    });
}

const Root = () => (
    <AuthOidcProvider>
        <AuthBarrierProvider>
            <App/>
        </AuthBarrierProvider>
    </AuthOidcProvider>
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
    },
    {
        path: '/logout-start',
        element: <LogoutStart/>
    },
    {
        path: 'logout-end',
        element: <LogoutEnd/>
    },
    {
        path: "*",
        element: <Root/>,
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
