import React from 'react';
import LogoImage from '../Logo/Logo';
import {HeaderProps} from '.';

import './Header.scss';

const Header: React.FC<HeaderProps> = ({activeDistrict, onLogout,}) => (
    <header className='tutoring-portal-header tutoring-portal-border-bottom'>
        <div className='container'>
            <div className="amplify-program-wordmark">
                <LogoImage/>
                <h1 className='program-name tutoring-portal-logo-title'>
                    Tutor Portal
                </h1>
            </div>
            {activeDistrict ? (
                <div className='tutoring-portal-active-district'>
                    <div className="active-label">
                        <div className="dot"></div>
                        ACTIVE DISTRICT
                    </div>
                    <a
                        href={undefined}
                        className='tutoring-portal-link default-link cursor'
                        onClick={onLogout}
                        rel="noreferrer"
                        target='_blank'
                        tabIndex={0}
                    >Log out of <b>{activeDistrict.district_name}</b></a>
                </div>
            ) : (
                <div className='tutoring-portal-active-district'>No active district</div>
            )}
        </div>
    </header>
)

export default Header;
