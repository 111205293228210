import React, {useLayoutEffect, useState} from "react";
import {AuthProvider} from "react-oidc-context";
import {getKeycloakAuthority, getOidcAuthority, getOidcClientId, getOidcRedirectUri} from "../shared/config";
import {removeQueryParamsFromURL} from "../utils/helpers";
import Keycloak from "keycloak-js";

export const keycloakConfig = {
    url: getKeycloakAuthority(),
    realm: 'Amplify',
    clientId: 'tutor-portal'
};

export let keycloakInstance: Keycloak;

const AuthOidcProvider: React.FC<AuthOidcProviderProps> = ({
                                                               children,
                                                           }): JSX.Element | null => {
    const [loading, setLoading] = useState(true);
    const redirectUri = getOidcRedirectUri();
    const oidcConfig = {
        authority: getOidcAuthority(),
        client_id: getOidcClientId(),
        redirect_uri: redirectUri,
        post_logout_redirect_uri: redirectUri,
        automaticSilentRenew: false,
        stopCheckSessionOnError: true,
        monitorSession: true,
        // You must provide an implementation of onSigninCallback to oidcConfig to remove the payload
        // from the URL upon successful login.
        // Otherwise if you refresh the page and the payload is still there, signinSilent - which handles renewing your token - won't work.
        onSigninCallback: removeQueryParamsFromURL,
    };

    useLayoutEffect(() => {
        const init = async () => {
            keycloakInstance = new Keycloak(keycloakConfig);
            keycloakInstance.onReady = (isReady) => {
                if (isReady && keycloakInstance.authenticated || !isReady) {
                    setLoading(false);
                }
            }

            await keycloakInstance.init({
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: `${getOidcRedirectUri()}/silent-check-sso.html`,
            });
        };

        init();
    }, []);

    return !loading ? (
        <AuthProvider {...oidcConfig}>
            {children}
        </AuthProvider>
    ) : null;
};

export default AuthOidcProvider;
