import {MappedAccount, SyncSubstituteRequest} from "../services";

export class SyncSubstituteDTO {
    static transformToRequest(users: MappedAccount[]): SyncSubstituteRequest['users'] {
        return users.map(user => ({
            user_id: `urn:mclass-staff:${user.amp_user_sid}`,
            email: user.email,
            account_uid: user.account_sid,
            district_uid: user.inst_sid
        }));
    }
}
