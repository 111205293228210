import React, { useEffect } from 'react';
import Logo from "./components/Logo/Logo";
import {getLogoutLink, getOidcRedirectUri} from "./shared/config";

const LogoutStart = () => {
    useEffect(() => {
        setTimeout(() => {
            window.location.assign(getLogoutLink(`${getOidcRedirectUri()}/logout-end`));
        }, 1000);
    }, []);

    return (
        <div
            style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <div>
                <Logo />
            </div>
            <h2>Logging out of your active district...</h2>
        </div>
    );
}

export default LogoutStart;
