import React from "react";
import classnames from 'classnames';

import './RedirectLink.scss';

const RedirectLink: React.FC<RedirectLinkProps> = ({
                                                       children,
                                                       uppercase = false,
                                                       classNames = '',
                                                       type = 'default',
                                                       disabled = false,
                                                       action,
                                                       kcAccessToken,
                                                       targetAmpUserSid,
                                                       targetUserType,
                                                       redirectUri,
                                                       onClick = () => undefined,
                                                   }) => (
    // eslint-disable-next-line
    <form
        role="form"
        className={classnames("tutoring-portal-link", {
            'cursor-pointer': !disabled
        })}
        action={action} method="post" target="_blank"
        onSubmit={onClick}
    >
        <input type="hidden" id="kcAccessToken" name="kc_access_token" value={kcAccessToken}/>
        <input type="hidden" id="targetAmpUserSid" name="target_amp_user_sid" value={targetAmpUserSid}/>
        <input type="hidden" id="targetUserType" name="target_user_type" value={targetUserType}/>
        <input type="hidden" id="redirectUri" name="redirect_uri" value={redirectUri}/>
        <span style={{display: 'inline-block'}}>
            <button
                className={classnames('tutoring-portal-link', classNames, {
                    uppercase: uppercase,
                    'btn btn-oval btn-small': type === 'button',
                    'default-link': type === 'default',
                    'simple-link': type === 'simple',
                })}
                type="submit"
                disabled={disabled}
            >
                {children}
            </button>
        </span>
    </form>
);

export default RedirectLink;
