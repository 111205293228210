import React from "react";

import './Error.scss';

const Error: React.FC<ErrorProps> = ({ title, description }) => (
    <div className="container error-page">
        <h2>
            <i className="icon-v2-messaging-error rhythm-icon"></i>
            {title}
        </h2>
        <p>{description}</p>
    </div>
);

export default Error;
