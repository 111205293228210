import React from "react";
import classnames from 'classnames';

import './Link.scss';

const Link: React.FC<LinkProps> = ({
                                       children,
                                       href,
                                       openInNewWindow = false,
                                       uppercase = false,
                                       classNames = '',
                                       title,
                                       type = 'default',
                                       onClick,
                                   }) => {
    return (
        <a
            className={classnames('tutoring-portal-link', classNames, {
                uppercase: uppercase,
                'btn btn-oval btn-small': type === 'button',
                'default-link': type === 'default',
                'simple-link': type === 'simple',
            })}
            href={href}
            target={openInNewWindow ? '_blank' : '_self'}
            rel="noreferrer"
            onClick={(e) => {
                if (onClick) {
                    e.preventDefault();
                    onClick();
                }
            }}
            title={title}
            tabIndex={0}
        >
            {children}
        </a>
    )
}

export default Link;
