import { AxiosInstance } from 'axios';
import { datadogLogs } from '@datadog/browser-logs';
import { BffApi, GetAllDistrictsResponse } from '.';

const bffApi: BffApi = (axios: AxiosInstance) => ({
    getEmails: async (email: string) => {
        try {
            return await axios.get<GetAllDistrictsResponse>('get_all_districts', {
                params: {
                    email: email,
                }
            });
        } catch (error) {
            datadogLogs.logger.error(`Districts loading failed for ${email}`, {}, error as Error);
            throw error;
        }
    },
    getInstitutions: async (ids: Number[]) => {
        try {
            const q = new URLSearchParams();
            q.set('institution_ids', String(ids));

            return await axios.get<any>(`get_all_internal_institutions?${q.toString()}`);
        } catch(error) {
            datadogLogs.logger.error(`Institutions loading failed for ${ids}`, {
                institution_ids: ids
            }, error as Error);
            throw error;
        }
    }
});

export default bffApi;
